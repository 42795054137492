import React from "react"
import styled from "@emotion/styled"

const breakpoints = [576, 768, 992, 1200]
const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

const WrapperWrapper = styled.section`
  margin: 1em 1em 0 1em;
  ${mq[2]} {
    margin: 1.5em 1.5em 0 1.5em;
  }
  ${mq[3]} {
    margin: 2em 2em 0 2em;
  }
`

function Wrapper({ children }) {
  return <WrapperWrapper>{children}</WrapperWrapper>
}

export default Wrapper
