// /src/components/ImageBlock.js
import React from "react"
// Gatsby provided Image tag
import Image from "gatsby-image"
// To convert Sanity image source to Gatsby Fluid image
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import Wrapper from "../modules/wrapper"

// Sanity project config
const sanityConfig = { projectId: "1inc05pd", dataset: "production" }

const ImageBlock = ({ node }) => {
  const { asset } = node

  // Convert to a Fluid Gatsby image
  const fluidProps = getFluidGatsbyImage(
    asset._ref,
    { maxWidth: 2000 },
    sanityConfig
  )

  // Render the image
  return (
    <Wrapper>
      <Image fluid={fluidProps} />
    </Wrapper>
  )
}

export default ImageBlock
