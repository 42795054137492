import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
//import PortableText and urlBuilder
import PortableText from "@sanity/block-content-to-react"
import Layout from "../components/layout"
import Image from "gatsby-image"
import ImageBlock from "../components/modules/imageBlock"
import TwoImageBlock from "../components/modules/twoImageBlock"
import Wrapper from "../components/modules/wrapper"
import MoreProjects from "../components/modules/moreProjects"

// Deals with data that comes in as custom blocks
// types are the custom blocks. you pass them props that return react components
const serializer = {
  types: {
    image: ({ node }) => <ImageBlock node={node} />,
    twoImages: ({ node }) => <TwoImageBlock node={node} />,
    // twoImages: props => <pre>{JSON.stringify(props, null, 2)}</pre>,
  },
}

export const query = graphql`
  query($slug: String) {
    sanityProject(slug: { current: { eq: $slug } }) {
      title
      subtitle
      client
      services
      _rawResults
      _rawStrategy
      collaborators {
        person
        service
      }
      _rawBody
      mainImage {
        asset {
          fluid(maxWidth: 2000) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`
// Styles

const breakpoints = [576, 768, 992, 1200]
const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

const ProjTitle = styled.h1`
  font-size: 1.625rem;
  ${mq[0]} {
    font-size: 1.625rem;
  }
  ${mq[1]} {
    font-size: 1.875rem;
  }
  ${mq[2]} {
    font-size: 3rem;
  }
  ${mq[3]} {
    font-size: 3.5rem;
  }
`
const ProjSubtitle = styled.h2`
  margin-bottom: 3rem;
  font-size: 1rem;
  font-style: italic;
  ${mq[0]} {
    font-size: 1rem;
  }
  ${mq[1]} {
    font-size: 1.125rem;
  }
  ${mq[2]} {
    font-size: 1.25rem;
  }
  ${mq[3]} {
    font-size: 1.25rem;
  }
`

const TextGrid = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  p {
    margin-top: .5rem;
    margin-bottom: 2rem;
  }
  ul { 
    margin-top: .5rem;
    margin-bottom: 2rem;
  }
  ${mq[0]} {
    grid-template-columns: repeat(4, [col-start] 1fr);
    grid-gap: 1rem;
    p {
      margin-top: 0;
    }
    ul { margin-top: 0;}
  }
  ${mq[1]} {

  }
  ${mq[2]} {
    margin-top: 1.5rem;
    grid-template-columns: repeat(6, [col-start] 1fr);
  }
  ${mq[3]} {
    margin-top: 2rem;
  }
`
const Strategy = styled.h3`
  ${mq[0]} {
    grid-column: 1 / span 1;
  }
  ${mq[1]} {
    grid-column: 2 / span 1;
  }
  ${mq[2]} {
    grid-column: 2 / span 1;
  }
`
const StrategyTextWrapper = styled.div`
  ${mq[0]} {
    grid-column: 2 / -1;
  }
  ${mq[1]} {
    grid-column: 3 / -1;
  }
  ${mq[2]} {
    grid-column: 3 / span 3;
  }
  ${mq[3]} {
    grid-column: 3 / span 2;
  }
`

const Design = styled.h3`
  ${mq[0]} {
    grid-column: 1 / span 1;
  }
  ${mq[1]} {
    grid-column: 2 / span 1;
  }
  ${mq[2]} {
    grid-column: 2 / span 1;
  }
`
const DesignTextWrapper = styled.div`
  ${mq[0]} {
    grid-column: 2 / -1;
  }
  ${mq[1]} {
    grid-column: 3 / -1;
  }
  ${mq[2]} {
    grid-column: 3 / span 3;
  }
  ${mq[3]} {
    grid-column: 3 / span 2;
  }
`

const ServicesWrapper = styled.div`
  ${mq[0]} {
    grid-column: 2 / -1;
  }
  ${mq[1]} {
    grid-column: 3 / -1;
  }
  ${mq[2]} {
    grid-column: 3 / span 3;
  }
  ${mq[3]} {
    grid-column: 3 / span 2;
  }
`;

const Services = styled.h3`
  ${mq[0]} {
    grid-column: 1 / span 1;
  }
  ${mq[1]} {
    grid-column: 2 / span 1;
  }
  ${mq[2]} {
    grid-column: 2 / span 1;
  }
`;

export default ({ data }) => (
  <Layout>
    <div>
      <Image fluid={data.sanityProject.mainImage.asset.fluid} />
      <Wrapper>
        <ProjTitle>{data.sanityProject.title}</ProjTitle>
        <ProjSubtitle>{data.sanityProject.subtitle}</ProjSubtitle>
        {/* <p>
          <em>Client: </em>
          {data.sanityProject.client}
        </p> */}
        <TextGrid>
          <Strategy>Challenge</Strategy>
          <StrategyTextWrapper>
            <PortableText blocks={data.sanityProject._rawStrategy || []} />
          </StrategyTextWrapper>

          <Design>Outcome</Design>
          <DesignTextWrapper>
            <PortableText blocks={data.sanityProject._rawResults || []} />
          </DesignTextWrapper>
            <Services>Services we provided</Services>
          <ServicesWrapper>
            <ul>
              {data.sanityProject.services.map(service => (
                <li>{service}</li>
              ))}
            </ul>
          </ServicesWrapper>
        </TextGrid>
      </Wrapper>
      <PortableText
        blocks={data.sanityProject._rawBody}
        serializers={serializer}
      />
      <Wrapper>
        <MoreProjects />
      </Wrapper>
    </div>
  </Layout>
)
