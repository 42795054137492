// /src/components/ImageBlock.js
import React from "react"
// Gatsby provided Image tag
import Image from "gatsby-image"
// To convert Sanity image source to Gatsby Fluid image
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import styled from "@emotion/styled"

const breakpoints = [576, 768, 992, 1200]
const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  margin: 1rem;
  gap: 1rem;
  ${mq[0]} {
    grid-template-columns: 1fr 1fr;
  }
  ${mq[2]} {
    margin: 1.5rem;
    gap: 1.5rem;
  }
  ${mq[3]} {
    margin: 2rem;
    gap: 2rem;
  }
`

// Sanity project config
const sanityConfig = { projectId: "1inc05pd", dataset: "production" }

const TwoImageBlock = ({ node }) => {
  // const { asset } = node

  // Convert to a Fluid Gatsby image
  const fluidProps1 = getFluidGatsbyImage(
    node.image1.asset._ref,
    { maxWidth: 1000 },
    sanityConfig
  )
  const fluidProps2 = getFluidGatsbyImage(
    node.image2.asset._ref,
    { maxWidth: 1000 },
    sanityConfig
  )

  // Render the image
  return (
    <Wrapper>
      {/* <h1>Two Images</h1>
      <pre>{JSON.stringify(node, null, 2)}</pre> */}
      <Image fluid={fluidProps1} />
      <Image fluid={fluidProps2} />
    </Wrapper>
  )
}

export default TwoImageBlock
