import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import Image from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

// Styles
const breakpoints = [576, 768, 992, 1200]
const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

const FpWrap = styled.section`
  /* margin-top: 2rem; */
  /* margin: 0.75rem 0.75rem 0rem 0.75rem;

  ${mq[2]} {
    margin: 1.125rem 1.125rem 0rem 1.125rem;
  }
  ${mq[3]} {
    margin: 1.5rem 1.5rem 0rem 1.5rem;
  } */
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  ${mq[0]} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mq[1]} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mq[2]} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mq[3]} {
    grid-template-columns: repeat(3, 1fr);
  }
  grid-gap: 1rem;
`

const Container = styled.div`
  /* background-color: #ffffff; */
  /* border: 1px solid #111111; */
  display: flex;
  flex-direction: column;
  margin: 0.25rem 0.25rem 0.25rem 0.25rem;

  ${mq[2]} {
    margin: 0.375rem 0.375rem 0.375rem 0.375rem;
  }
  ${mq[3]} {
    margin: 0.5rem 0.5rem 0.375rem 0.5rem;
  }
`

const WorkDesc = styled.div`
  padding: 1rem;

  h2 {
    margin-bottom: 1rem;
    line-height: 1.2;
  }

  p {
    line-height: 1.5;
  }
`

const WorkTitle = styled(Link)`
  color: #111;
  text-decoration: none;
`

const BottomBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 1rem 1rem 1rem;
  margin-top: auto;
  /* background: linear-gradient(#000, #000) no-repeat center/1px 100%; */
`

const WorkLink = styled(Link)`
  border: 1px solid #111;
  padding: 0.5rem 1rem;
  border-radius: 4rem;
  text-decoration: none;
  text-transform: uppercase;
  color: #111;
  font-size: 0.875rem;

  :hover {
    background-color: #111;
    color: #fff;
  }
`

const Arrow = styled.svg`
  width: 5rem;
`

export default function MoreProjects() {
  const data = useStaticQuery(graphql`
    query MoreProjectsQuery {
      sanityProject {
        featuredProjectsGrid {
          proj1 {
            title
            subtitle
            slug {
              current
            }
            mainImage {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          proj2 {
            title
            subtitle
            slug {
              current
            }
            mainImage {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          proj3 {
            title
            subtitle
            slug {
              current
            }
            mainImage {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          proj4 {
            title
            subtitle
            slug {
              current
            }
            mainImage {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <FpWrap>
      <Container>
        <Link to={data.sanityProject.featuredProjectsGrid.proj1.slug.current}>
          <Image
            fluid={
              data.sanityProject.featuredProjectsGrid.proj1.mainImage.asset
                .fluid
            }
          />
        </Link>
        <WorkDesc>
          <WorkTitle
            to={data.sanityProject.featuredProjectsGrid.proj1.slug.current}
          >
            <h2>{data.sanityProject.featuredProjectsGrid.proj1.title}</h2>
          </WorkTitle>
          <p>{data.sanityProject.featuredProjectsGrid.proj1.subtitle}</p>
        </WorkDesc>
        <BottomBox>
          <WorkLink
            to={data.sanityProject.featuredProjectsGrid.proj1.slug.current}
          >
            Case Study
          </WorkLink>
          <Arrow
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 55 31"
          >
            <path
              d="M0 15.5h54m0 0L42.449 1M54 15.5L42.449 30"
              stroke="#181818"
            />
          </Arrow>
        </BottomBox>
      </Container>

      <Container>
        <Link to={data.sanityProject.featuredProjectsGrid.proj2.slug.current}>
          <Image
            fluid={
              data.sanityProject.featuredProjectsGrid.proj2.mainImage.asset
                .fluid
            }
          />
        </Link>
        <WorkDesc>
          <WorkTitle
            to={data.sanityProject.featuredProjectsGrid.proj2.slug.current}
          >
            <h2>{data.sanityProject.featuredProjectsGrid.proj2.title}</h2>
          </WorkTitle>
          <p>{data.sanityProject.featuredProjectsGrid.proj2.subtitle}</p>
        </WorkDesc>
        <BottomBox>
          <WorkLink
            to={data.sanityProject.featuredProjectsGrid.proj2.slug.current}
          >
            Case Study
          </WorkLink>
          <Arrow
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 55 31"
          >
            <path
              d="M0 15.5h54m0 0L42.449 1M54 15.5L42.449 30"
              stroke="#181818"
            />
          </Arrow>
        </BottomBox>
      </Container>

      <Container>
        <Link to={data.sanityProject.featuredProjectsGrid.proj3.slug.current}>
          <Image
            fluid={
              data.sanityProject.featuredProjectsGrid.proj3.mainImage.asset
                .fluid
            }
          />
        </Link>
        <WorkDesc>
          <WorkTitle
            to={data.sanityProject.featuredProjectsGrid.proj3.slug.current}
          >
            <h2>{data.sanityProject.featuredProjectsGrid.proj3.title}</h2>
          </WorkTitle>
          <p>{data.sanityProject.featuredProjectsGrid.proj3.subtitle}</p>
        </WorkDesc>
        <BottomBox>
          <WorkLink
            to={data.sanityProject.featuredProjectsGrid.proj3.slug.current}
          >
            Case Study
          </WorkLink>
          <Arrow
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 55 31"
          >
            <path
              d="M0 15.5h54m0 0L42.449 1M54 15.5L42.449 30"
              stroke="#181818"
            />
          </Arrow>
        </BottomBox>
      </Container>

      <Container>
        <Link to={data.sanityProject.featuredProjectsGrid.proj4.slug.current}>
          <Image
            fluid={
              data.sanityProject.featuredProjectsGrid.proj4.mainImage.asset
                .fluid
            }
          />
        </Link>
        <WorkDesc>
          <WorkTitle
            to={data.sanityProject.featuredProjectsGrid.proj4.slug.current}
          >
            <h2>{data.sanityProject.featuredProjectsGrid.proj4.title}</h2>
          </WorkTitle>
          <p>{data.sanityProject.featuredProjectsGrid.proj4.subtitle}</p>
        </WorkDesc>
        <BottomBox>
          <WorkLink
            to={data.sanityProject.featuredProjectsGrid.proj4.slug.current}
          >
            Case Study
          </WorkLink>
          <Arrow
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 55 31"
          >
            <path
              d="M0 15.5h54m0 0L42.449 1M54 15.5L42.449 30"
              stroke="#181818"
            />
          </Arrow>
        </BottomBox>
      </Container>
    </FpWrap>
  )
}
